import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { connect } from 'react-redux'

import PopupComponent from 'libs/modals/popups'
import popupClasses from 'libs/modals/popups.module.scss'
import * as forms from 'libs/forms'
import layout from 'ui/layout/layout.module.scss'
import FlatButton from 'components/buttons/FlatButton'
import Spacer from 'ui/layout/Spacer'
import VStack from 'ui/layout/VStack'
import { initializeForm } from 'redux/modules/forms'
import { makeTestID } from 'libs/utils'

const formName = 'updateOnlineURL'

const baseTestID = makeTestID('sellOnline', 'settings')

class UpdateOnlineURL extends PopupComponent {
  componentDidMount() {
    this.props.dispatch(
      initializeForm(formName, {
        path: this.props.path,
      })
    )
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    super.UNSAFE_componentWillReceiveProps(nextProps)

    if (nextProps.path !== this.props.path) {
      this.props.dispatch(
        initializeForm(formName, {
          path: nextProps.path,
        })
      )
    }
  }

  getContent() {
    return (
      <div>
        <div className={popupClasses.popupTitle}>Edit online link</div>
        <div className={popupClasses.popupContent}>
          <Spacer size='large' />

          <p>
            You can update the link that is used for all of your online products. This will appear
            at the end of the website address. e.g. pay.yoco.com/
            {this.props.path}
          </p>

          <Spacer size='large' />

          <forms.Form
            action='/paymentPage/path'
            name={formName}
            onSuccess={(data) => {
              this.props.onPathUpdated(data.data.paymentPage.path, data.data.paymentPage.url)
            }}
          >
            <VStack spacing='medium'>
              <forms.TextField
                name='path'
                label='Online link'
                description='This may only contain letters, numbers and the dash symbol'
                isFullWidth
                initialValue={this.props.path}
                testID={makeTestID(baseTestID, 'onlineLink')}
              />

              <FlatButton
                label='Save link'
                className={classnames('blueBackground', layout.widthFull)}
                type='submit'
                testID={makeTestID(baseTestID, 'saveLink')}
              />
            </VStack>
          </forms.Form>
        </div>
      </div>
    )
  }
}

UpdateOnlineURL.propTypes = {
  ...PopupComponent.propTypes,
  onPathUpdated: PropTypes.func,
}

export default connect(() => ({}))(UpdateOnlineURL)
