/* eslint-disable no-param-reassign */
import { fromJS } from 'immutable'

export const productToInvoiceEntry = (product, selectedVariant) => {
  // If no variant selected, get the first one possible, or create based on the product
  if (!selectedVariant) {
    selectedVariant =
      Array.isArray(product.variants) && product.variants.length
        ? product.variants[0]
        : {
            uuid: `${product.uuid}-${Date.now().toString()}`,
            productUUID: product.uuid,
            skuUUID: product.skuUUID,
            tileUUID: product.tileUUID,
            choiceGroups: [],
            choiceValues: [],
            enabled: true,
          }
  }

  const price = selectedVariant.price || product.defaultPrice

  return fromJS({
    clientBillEntryIdentifier: `${product.uuid}-${Date.now().toString()}`,
    discount: 0,
    meta: selectedVariant.choiceValues?.length
      ? {
          variantChoices: selectedVariant.choiceValues,
        }
      : {},
    salesTaxes: (product.appliedSalesTaxes || []).map(({ salesTaxUUID, taxName, salesTax }) => ({
      salesTaxUUID,
      taxAmount: price * salesTax.taxPercentageOfTotal,
      taxName,
      taxPercentageOfTotal: salesTax.taxPercentageOfTotal,
    })),
    price: selectedVariant.price || product.defaultPrice,
    quantity: 1,
    tile: product.tile,
    variantUUID: selectedVariant.uuid,
    variantChoiceValues: selectedVariant.choiceValues || [],
  })
}
