/* eslint-disable no-restricted-globals */
/* eslint-disable no-return-assign */
import React, { useState } from 'react'
import { Link } from 'react-router'
import { push } from 'react-router-redux'
import PropTypes from 'prop-types'

import * as forms from 'libs/forms'
import FlatButton from 'components/buttons/FlatButton'
import { userLoggedIn } from 'libs/analytics'
import { saveSession } from 'libs/cookies'
import { getQueryParamByName, getQueryParams } from 'libs/queryParams'
import { loggedIn } from 'redux/modules/session'
import yocoImage from 'assets/images/yoco.png'
import { getENV } from 'libs/utils'

import LoadingScreen from '../online/components/LoadingScreen'

import classes from './account.module.scss'

function ShopifyLogin({ props, hideMessage }) {
  const [cancelClicked, setCancelClicked] = useState(false)

  const nextUrlParam = window && window.location ? window.location.search || '' : ''

  const cancelInstallation = () => {
    setCancelClicked(true)

    const params = getQueryParams(true)
    const next = params.next || ''
    let shopifyUrl
    try {
      const decodedUrl = new URL(window.location.origin + next)
      shopifyUrl = decodedUrl.searchParams.get('shop')
    } catch (e) {
      console.log(e)
    }
    if (shopifyUrl) {
      setTimeout(() => (window.location.href = `https://${shopifyUrl}/admin`), 3000)
    } else {
      setTimeout(() => history.back(), 3000)
    }
  }

  const handleSuccess = (data) => {
    const session = {
      user: data.data.user,
      business: data.data.business,
      token: data.data.token,
      merchant: data.data.merchant,
      features: data.data.features,
      roles: data.data.roles,
    }

    const next = getQueryParamByName('next')
    saveSession(session)
    userLoggedIn(session.user)
    /* eslint-disable react/prop-types */
    props.dispatch(loggedIn(session))
    props.dispatch(push(next))
    /* eslint-enable react/prop-types */
  }

  return cancelClicked ? (
    <LoadingScreen
      image={yocoImage}
      title="Okay, we won't configure Yoco App"
      subtitle="In a few seconds you'll be redirected back"
    />
  ) : (
    <forms.Form
      name='login'
      action='/user/login'
      noWrap
      onSuccess={(data) => handleSuccess(data)}
      addUTM
      loaderClass={classes.formLoader}
      onValidated={() => hideMessage()}
    >
      <forms.TextField
        name='email'
        label='Email address'
        placeholder='Please enter your email address'
        type='email'
        validators={[
          new forms.RequiredValidator('You must provide a email address'),
          new forms.EmailValidator('You must provide an email address'),
        ]}
      />
      <forms.TextField
        name='password'
        placeholder='Please enter your password'
        type='password'
        validators={[new forms.RequiredValidator('Please type in your password')]}
      />
      <div className={`${classes.flexButtonContainer}`}>
        <FlatButton
          type='button'
          label='Cancel'
          className={`${classes.cancelButton}`}
          onClick={() => cancelInstallation()}
        />
        <FlatButton label='Login' className={`${classes.shopifyLoginButton}`} type='submit' />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
        <span>
          <Link to={`/account/forgot-password${nextUrlParam}`}>Forgot password?</Link>
        </span>
        <span style={{ marginTop: '8px' }}>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          Haven't got an account yet?
          <Link
            to={{ pathname: `${getENV('HELLO_YOCO_URL')}signup${nextUrlParam}` }}
            target='_blank'
          >
            &nbsp;Sign Up
          </Link>
        </span>
      </div>
    </forms.Form>
  )
}

ShopifyLogin.propTypes = {
  props: PropTypes.object.isRequired,
  hideMessage: PropTypes.bool,
}

export default ShopifyLogin
