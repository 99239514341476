import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@yoco/design-system-icons/dist/react'
import { Map } from 'immutable'

import { FirebaseHandler } from 'libs/firebase'
import { List } from 'libs/list'
import FlatButton from 'components/buttons/FlatButton'
import emptyBasket from 'assets/images/icon-empty-state.png'
import LoadingView from 'components/loaders/LoadingView'
import { makeTestID } from 'libs/utils'

import classes from './List.module.scss'
import AddProductListItem from './ListItem'

function AddProductList({
  products: firebaseData,
  onAddProductClick,
  onDone,
  receivedAllEvents,
  testID,
}) {
  const [search, setSearch] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [selection, setSelection] = useState(new Map())

  useEffect(() => {
    if (receivedAllEvents) {
      setIsLoading(false)
    }
  }, [receivedAllEvents])

  const data = useMemo(
    () =>
      firebaseData
        ?.toList()
        .sortBy((item) => item.getIn(['tile', 'name']))
        .toJS(),
    [firebaseData]
  )

  const handleSelectionChange = useCallback(
    (key, value) => {
      if (value) {
        setSelection(selection.set(key, value))
      } else {
        setSelection(selection.delete(key))
      }
    },
    [selection, setSelection]
  )

  const products = useMemo(() => {
    if (!Array.isArray(data)) {
      return []
    }

    return search
      ? data.filter(({ tile }) => (tile?.name || '').toLowerCase().includes(search.toLowerCase()))
      : data
  }, [data, search])

  let content
  if (isLoading) {
    content = <LoadingView message='Loading products ...' />
  } else if (data) {
    content = (
      <>
        <div className={classes.search}>
          <input
            placeholder='Type to filter ...'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <Icon name='search' size={24} />
        </div>
        <List
          hasOutlineBorders
          data={products}
          renderItem={(product) => (
            <AddProductListItem
              product={product}
              selection={selection.get(product.uuid)}
              onSelectionChange={(nextSelection) => {
                handleSelectionChange(product.uuid, nextSelection)
              }}
            />
          )}
        >
          {!products?.length ? (
            <div className={classes.empty}>
              <img src={emptyBasket} alt='Empty basket' /> <br />
              No products were found
            </div>
          ) : null}
        </List>
        <div className={classes.footer}>
          <FlatButton className='blueBackground' onClick={() => onDone?.(selection.toList())}>
            Done
          </FlatButton>
        </div>
      </>
    )
  } else {
    content = (
      <div className={classes.empty}>
        <img src={emptyBasket} alt='Empty basket' /> <br />
        <b>You have no products in your collection</b> <br />
        <br />
        <FlatButton
          type='button'
          onClick={onAddProductClick}
          testID={makeTestID(testID, 'noProducts', 'createNewProduct')}
        >
          Create a new one
        </FlatButton>
      </div>
    )
  }

  return <div className={classes.root}>{content}</div>
}

AddProductList.propTypes = {
  products: PropTypes.object,
  onDone: PropTypes.func,
  onAddProductClick: PropTypes.func,
  receivedAllEvents: PropTypes.bool,
  testID: PropTypes.string,
}

export default FirebaseHandler.connect(
  AddProductList,
  Map({
    products: {
      path: 'readOnly/store/products',
    },
  })
)
