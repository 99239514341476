/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react'
import { browserHistory } from 'react-router'
import classnames from 'classnames'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Icon } from '@yoco/design-system-icons/dist/react'

import MaxWidthBlock from 'components/blocks/MaxWidthBlock'
import Spacer from 'ui/layout/Spacer'
import FlatButton from 'components/buttons/FlatButton'
import { callJSONApi } from 'libs/api'
import LoadingView from 'components/loaders/LoadingView'
import Alert from 'components/notifications/Alert'
import VStack from 'ui/layout/VStack'
import { track } from 'libs/analytics'
import UpdateOnlineURL from 'containers/online/UpdateOnlineURL'
import BlockHeader from 'components/headers/BlockHeader'
import * as forms from 'libs/forms'
import buttonClasses from 'components/buttons/buttons.module.scss'
import ItemImageUpload from 'components/items/ItemImageUpload'
import { initializeForm, fieldUpdated } from 'redux/modules/forms'
import classes from 'containers/online/online.module.scss'
import RemovableImage from 'ui/components/RemovableImage'
import { makeTestID } from 'libs/utils'
import InfoCardHolder from 'components/infoCards/InfoCard'
import logo from 'assets/images/online/logo-placeholder.png'
import SubHeading from 'ui/components/SubHeading'
import { If } from 'libs/formats'
import PreventBeforeFullyOnboarded from 'containers/onboarding/PreventBeforeFullyOnboarded'

const formName = 'onlineSettings'

class OnlineSettingsPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      showUpdateOnlineURL: false,
    }
  }

  componentDidMount() {
    callJSONApi(
      '/paymentPage/',
      'GET',
      null,
      (data) => {
        const paymentPages = data.data.paymentPages || []
        if (paymentPages.length > 0) {
          const paymentPage = paymentPages[0]

          this.props.initializeForm({
            path: paymentPage.path,
            logoUUID: paymentPage.storedImageUUID,
            onlineEmail: paymentPage.onlineEmail,
            onlinePhoneNumber: paymentPage.onlinePhoneNumber,
          })

          this.setState({
            isLoading: false,
            errorMessage: null,
            paymentPage,
            logoURL: paymentPage.logoURL,
          })
        } else {
          browserHistory.push('/online/activate')
        }
      },
      (prettyError) => {
        this.setState({
          isLoading: false,
          errorMessage: prettyError,
        })
      }
    )
  }

  copyPaymentPageUrl = () => {
    this.copyUrl(this.state.paymentPage.url)
    track('copy_payment_link_button_clicked', { name: 'Copy' })
  }

  copyUrl(url) {
    const copyTextarea = document.querySelector('#copyUrl')
    copyTextarea.select()

    try {
      const successful = document.execCommand('copy')
      if (successful) {
        this.setState({
          showSuccesful: true,
        })
      } else {
        window.prompt(
          `To copy to the clipboard right click and select "copy" or press: Ctrl+C (Cmd + C on a mac)`,
          url
        )
      }
    } catch (err) {
      window.prompt(
        `To copy to the clipboard right click and select "copy" or press: Ctrl+C (Cmd + C on a mac)`,
        url
      )
    }
  }

  render() {
    if (this.state.isLoading) {
      return <LoadingView />
    }

    const { paymentPage } = this.state

    return (
      <MaxWidthBlock className={classes.pageBG}>
        <PreventBeforeFullyOnboarded />
        <input
          id='copyUrl'
          value={paymentPage.url}
          style={{ position: 'absolute', top: '-1000px' }}
        />
        <BlockHeader title='Payment Page' />
        <InfoCardHolder paymentPageInfo />

        <If condition={this.state.errorMessage}>
          <Alert
            messageType='danger'
            message={this.state.errorMessage}
            closable
            onClose={() => this.setState({ errorMessage: null })}
          />
        </If>
        <If condition={paymentPage}>
          <div>
            <forms.FormBlock>
              <SubHeading>Payment Page link</SubHeading>
              <p>Copy the link and share it online or add it to your invoice template.</p>
              <div className={classes.inputContainer}>
                <div onClick={this.copyPaymentPageUrl} className={classes.container}>
                  <div className={classes.icon}>
                    <Icon name='copy' size={24} />
                  </div>
                  <input
                    className={classes.text}
                    value={paymentPage.url}
                    data-testid={makeTestID('sellOnline', 'settings', 'paymentPageLink')}
                  />
                </div>
                <div className={classes.buttonContainer}>
                  <FlatButton
                    label='Copy'
                    className={`secondary formFieldButton ${classes.button}`}
                    onClick={this.copyPaymentPageUrl}
                    testID={makeTestID('sellOnline', 'settings', 'copy')}
                  />
                  <FlatButton
                    label='Edit link'
                    className={`tertiary formFieldButton ${classes.button}`}
                    onClick={() =>
                      this.setState({ showUpdateOnlineURL: true, showSuccesful: false })
                    }
                    testID={makeTestID('sellOnline', 'settings', 'editMyLink')}
                  />
                </div>
              </div>
              <If condition={this.state.showSuccesful}>
                <div className={classes.copyText}>Copied to clipboard!</div>
              </If>
              <div className='clearfix' />
            </forms.FormBlock>

            <forms.Form
              name={formName}
              action={`/paymentPage/${this.state.paymentPage.uuid}`}
              persistData
              showSuccess
            >
              <forms.FormBlock className='form half-width'>
                <BlockHeader title='Payment page content' />
                <p>
                  These details will be displayed on your online payment products, for your
                  customers to see. Please note, that changing these details do not change the
                  contact info we have on record for you.
                </p>

                <Spacer size='medium' />

                <forms.Label>Logo</forms.Label>
                <div className={classes.logoHolder}>
                  {this.state.logoURL ? (
                    <RemovableImage
                      alt=''
                      className={classes.logo}
                      src={this.state.logoURL}
                      onClick={() => {
                        this.setState({ logoURL: null })
                        this.props.setLogo(null)
                      }}
                    />
                  ) : (
                    <img width='100px' alt='logo' src={logo} className={classes.logo} />
                  )}

                  <div className={classes.uploadImageContent}>
                    <p>
                      Your logo must be a square image that is
                      <br />
                      at least 500 x 500 pixels in size.
                    </p>
                    <VStack>
                      <div style={{ position: 'relative', display: 'inline-block' }}>
                        <button
                          type='button'
                          className={classnames(buttonClasses.flatButton, 'secondary')}
                          style={{
                            position: 'relative',
                            cursor: 'pointer',
                          }}
                        >
                          Upload
                        </button>
                        <ItemImageUpload
                          grouping='business-logo'
                          fullCover
                          maxHeight={512}
                          format='image/jpg'
                          completeCallback={(data) => {
                            this.setState({ logoURL: data.data.url })
                            this.props.setLogo(data.data.uuid)
                          }}
                        />
                      </div>
                    </VStack>
                  </div>
                </div>
                <div className='clearfix' />
                <Spacer size='large' />
                <div className={classes.input}>
                  <forms.EmailField
                    name='onlineEmail'
                    label='Email address'
                    isHalfWidth
                    validators={[
                      new forms.EmailValidator('You must provide a valid email address'),
                    ]}
                  />
                  <forms.TextField
                    name='onlinePhoneNumber'
                    label='Contact number'
                    isHalfWidth
                    validators={[new forms.MobileValidator()]}
                  />
                </div>

                <FlatButton label='Save' type='submit' className='blueBackground' />
              </forms.FormBlock>
            </forms.Form>

            <UpdateOnlineURL
              path={paymentPage.path}
              showing={this.state.showUpdateOnlineURL}
              onPathUpdated={(path, url) => {
                this.setState((state) => ({
                  showUpdateOnlineURL: false,
                  paymentPage: {
                    ...state.paymentPage,
                    path,
                    url,
                  },
                }))
              }}
              onEsc={() => this.setState({ showUpdateOnlineURL: false })}
            />
          </div>
        </If>
      </MaxWidthBlock>
    )
  }
}

OnlineSettingsPage.propTypes = {
  initializeForm: PropTypes.func,
  setLogo: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => ({
  initializeForm: (values) => dispatch(initializeForm(formName, values)),
  setLogo: (uuid) => dispatch(fieldUpdated(formName, 'logoUUID', uuid)),
})
const ConnetedOnlineSettingsPage = connect(null, mapDispatchToProps)(OnlineSettingsPage)

export default ConnetedOnlineSettingsPage
