import invoiceImg from 'assets/images/online/onboarding_invoice_card@2x.png'
import paymentLinkImg from 'assets/images/online/onboarding_payment_link_card@2x.jpg'
import paymentGatewayImg from 'assets/images/online/onboarding_payment_gateway_card@2x.jpg'

export const infoCards = ({ hasManagePaymentLinksPortal }) => [
  {
    img: invoiceImg,
    title: 'Create and send professional invoices.',
    blurb:
      'Send simple, professional invoices to your customers for free and get paid fast, online.',
    linkAHref: '/invoice/invoices',
  },
  {
    linkAText: 'Send your first invoice',
    popUp: false,
    tracking: 'complete_your_setup_online_payments_invoices',
  },
  {
    img: paymentLinkImg,
    title: 'Send a payment link to a customer.',
    blurb: `Set the sale amount${
      hasManagePaymentLinksPortal ? '' : ' in your Yoco app'
    }, then Whatsapp or email the link to your customer.`,
    linkAText: 'Send a payment link',
    linkAHref: '/online/payment-links',
    popUp: hasManagePaymentLinksPortal,
    tracking: hasManagePaymentLinksPortal ? 'payment-link' : 'download-app',
  },
  {
    img: paymentGatewayImg,
    title: 'Take payments on your website.',
    blurb: `Our payment gateway is compatible with major platforms like 
         WooCommerce, Wix and Shopstar.`,
    linkAText: 'Get your keys',
    linkAHref: '/online/plugin-keys/',
    popUp: false,
    tracking: 'get-keys',
  },
]

export default infoCards
