import Immutable, { Map } from 'immutable'
import { push } from 'react-router-redux'
import { callJSONApi } from 'libs/api'
import { saveSession } from 'libs/cookies'
import { userLoggedIn } from 'libs/analytics'
import { setUser as setSentryUser } from '@sentry/react'

export const ACTIONS = {
  LOGGED_IN: 'LOGGED_IN',
  LOGGED_OUT: 'LOGGED_OUT',
  IN_APP_LOGIN: 'IN_APP_LOGIN',
  FIREBASE_AUTH_FAILED: 'FIREBASE_AUTH_FAILED',
}

export function loggedIn(session) {
  const user = Immutable.fromJS(session.user)
  const business = Immutable.fromJS(session.business)
  window.ldClient.identify({ key: user.get('businessUUID') })
  setSentryUser({
    id: user.get('uuid'),
    email: user.get('email'),
    businessUUID: user.get('businessUUID'),
  })
  return {
    type: ACTIONS.LOGGED_IN,
    user,
    business,
    merchant: Immutable.fromJS(session.merchant),
    posFirebaseToken: session.posFirebaseToken,
    token: session.token,
    // localStorage saved features won't have this
    features: Map(
      Immutable.fromJS(session.features || []).map((feature) => {
        return [feature.get('featureType'), feature]
      })
    ),
    roles: Immutable.fromJS(session.roles),
  }
}

export function switchAccount(userUUID) {
  return (dispatch) => {
    console.log('Switching account to', userUUID)
    callJSONApi(
      `/user/linkedAccounts/${userUUID}/login`,
      'POST',
      {},
      (result) => {
        const { token } = result.data
        dispatch(setToken(token))
      },
      (prettyError) => {
        alert(`Unable to switch accounts: ${prettyError}`)
      }
    )
  }
}

export function setToken(token) {
  return (dispatch) => {
    console.log('Setting user token', token)

    window?.yocoStorage?.setItem('token', token)

    callJSONApi(
      '/user/session',
      'GET',
      {},
      (response) => {
        saveSession(response.data)
        userLoggedIn(response.data.user)
        dispatch(loggedIn(response.data))
        dispatch(push('/'))
      },
      (prettyError) => {
        console.error('Unable to fetch user session, redirecting to login', prettyError)
        alert(`Unable to fetch account: ${prettyError}`)
      }
    )
  }
}

export function loggedOut() {
  window.ldClient.identify({ key: 'Unauthenticated-User' })
  return {
    type: ACTIONS.LOGGED_OUT,
  }
}

export function inAppLoginUsed() {
  return {
    type: ACTIONS.IN_APP_LOGIN,
  }
}

export function firebaseAuthFailed() {
  return {
    type: ACTIONS.FIREBASE_AUTH_FAILED,
  }
}

const initialState = Map({
  user: Map({
    info: Map({
      firstName: undefined,
      lastName: undefined,
    }),
  }),
  business: Map({
    info: Map({
      registrationNumber: undefined,
    }),
  }),
  loggedIn: false,
})

export default function Session(state = initialState, action = {}) {
  switch (action.type) {
    case ACTIONS.LOGGED_IN: {
      return state
        .set('user', action.user.set('roles', action.roles))
        .set('business', action.business)
        .set('posFirebaseToken', action.posFirebaseToken)
        .set('merchant', action.merchant)
        .set('token', action.token)
        .set('loggedIn', true)
        .set('features', action.features)
    }
    case ACTIONS.LOGGED_OUT:
      return initialState
    case ACTIONS.IN_APP_LOGIN:
      return state.set('loggedIn', true)
    case ACTIONS.FIREBASE_AUTH_FAILED:
      return state.set('firebaseAuthFailed', true)
    default:
      return state
  }
}
