import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Map, List } from 'immutable'
import PropTypes from 'prop-types'
import { push } from 'react-router-redux'

import MaxWidthBlock from 'components/blocks/MaxWidthBlock'
import BlockHeader from 'components/headers/BlockHeader'
import * as forms from 'libs/forms'
import FlatButton from 'components/buttons/FlatButton'
import localisation from 'libs/localisation/localisation'
import { getProvinces, makeTestID } from 'libs/utils'

const baseTestID = makeTestID('businessSettings', 'details')

class UnconnectedBusinessDetailsPage extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  componentDidMount() {
    getProvinces(this.props.localisation.get('countryCode'), (data) =>
      this.setState({ provinces: data.data.map((p) => ({ label: p.name, value: p.name })) })
    )
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.formData !== this.props.formData) {
      this.setState({ defaultSalesTax: this.defaultSalesTax(nextProps) })
    }
  }

  getSalesTaxDescription(salesTax) {
    return `${salesTax.get('taxName')} (${(salesTax.get('taxPercentage') * 100).toFixed()}%)`
  }

  defaultSalesTax(props) {
    return props.formData
      .get('salesTaxes', List())
      .filter((salesTax) => salesTax.get('isEnabledByDefault'))
      .map((salesTax) => {
        return Map({
          name: this.getSalesTaxDescription(salesTax),
          value: salesTax.get('uuid'),
        })
      })
      .getIn([0, 'value'])
  }

  salesTaxes() {
    return this.props.formData
      .get('salesTaxes', List())
      .map((salesTax) => {
        return {
          label: this.getSalesTaxDescription(salesTax),
          value: salesTax.get('uuid'),
        }
      })
      .toSet()
      .reverse()
      .toArray()
  }

  render() {
    const taxNumberName = this.props.localisation.get('taxNumberName', 'VAT Number')
    return (
      <MaxWidthBlock>
        <BlockHeader title='Business Details' />
        <forms.Form
          className='form half-width'
          fetchAPI='/business/?display=tradingAddress'
          action='/business/info'
          name='businessDetails'
          dataPath='business'
          persistData
          showSuccess
          callback={(response) => {
            const tradingAddress = response.data?.business?.tradingAddress
            if (!tradingAddress) {
              this.props.redirectToOnboarding()
            }
          }}
        >
          <forms.FormBlock
            heading='Basic information'
            infoText={`
              This information will appear on the receipts that are emailed or SMSed to your customers. Please use a name that your customers will recognise  to avoid confusion.
            `}
          >
            <forms.TextField
              name='displayName'
              label='Trading Name'
              placeholder='Trading Name'
              validators={[new forms.RequiredValidator('You must provide a trading name')]}
              testID={makeTestID(baseTestID, 'tradingName')}
            />
            <forms.TextField
              name='info.telephoneNumber'
              label='Telephone Number'
              placeholder='Telephone Number'
              validators={[
                new forms.RequiredValidator('You must provide a contact number'),
                new forms.MobileValidator('You must provide a valid phone number'),
              ]}
              testID={makeTestID(baseTestID, 'telephoneNumber')}
            />
            <forms.TextField
              name='info.businessEmail'
              label='Business Email'
              placeholder='Business Email'
              validators={[new forms.EmailValidator('You must provide a valid email address')]}
              testID={makeTestID(baseTestID, 'businessEmail')}
            />
            <forms.TextField
              name='info.vatNumber'
              label={`${taxNumberName}`}
              placeholder={`${taxNumberName}`}
              testID={makeTestID(baseTestID, 'vatNumber')}
            />
            <forms.SelectField
              name='defaultSalesTax'
              label='Default Tax'
              placeholder='Please select one'
              options={this.salesTaxes()}
              defaultValue={this.state.defaultSalesTax}
              validators={[new forms.RequiredValidator()]}
            />
          </forms.FormBlock>
          <forms.FormBlock
            heading='Trading Address'
            infoText={`
              This is the physical location of your business. If your business is purely mobile, then please fill in your registered business address.
            `}
          >
            <forms.TextField
              name='tradingAddress.firstLine'
              label='Street address'
              placeholder='e.g. 12 Loop Street'
              validators={[new forms.RequiredValidator('This field is required')]}
            />
            <forms.TextField
              name='tradingAddress.secondLine'
              label='Suburb'
              placeholder='Suburb'
              validators={[new forms.RequiredValidator('This field is required')]}
            />
            <forms.TextField
              name='tradingAddress.thirdLine'
              label='Building/Complex (optional)'
              placeholder='Building name, unit number or floor'
            />
            <forms.TextField
              name='tradingAddress.city'
              label='City'
              placeholder='City'
              validators={[new forms.RequiredValidator('This field is required')]}
            />
            <forms.SelectField
              name='tradingAddress.province'
              label='Province'
              placeholder='Province'
              validators={[new forms.RequiredValidator('This field is required')]}
              options={this.state.provinces}
            />
            <forms.TextField
              name='tradingAddress.postalCode'
              label='Postal Code'
              placeholder='Postal Code'
              validators={[
                new forms.RequiredValidator('You must provide a postal code'),
                new forms.NumberValidator('You must provide a number'),
              ]}
            />
          </forms.FormBlock>
          <FlatButton
            label='Save'
            type='submit'
            className='blueBackground'
            testID={makeTestID(baseTestID, 'save')}
          />
        </forms.Form>
      </MaxWidthBlock>
    )
  }
}

UnconnectedBusinessDetailsPage.propTypes = {
  localisation: PropTypes.objectOf(Map),
  redirectToOnboarding: PropTypes.func,
  formData: PropTypes.objectOf(Map),
}

export default connect(
  (state) => ({
    formData: state.forms.getIn(['businessDetails', 'data'], Map()),
    localisation: localisation(state),
  }),
  (dispatch) => ({
    dispatch,
    redirectToOnboarding: () => dispatch(push('/onboarding')),
  })
)(UnconnectedBusinessDetailsPage)
