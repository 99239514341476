export function setCookie(name, value, domain, expiresDays = 14) {
  const exDate = new Date()
  exDate.setDate(exDate.getDate() + expiresDays)

  document.cookie = `${name}=${value};path=/;domain=${
    domain || window.location.hostname
  };expires=${exDate.toGMTString()};`
}

export function getCookie(cname) {
  const name = `${cname}=`
  const ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i]
    while (c.charAt(0) === ' ') c = c.substring(1)
    if (c.indexOf(name) === 0) return c.substring(name.length, c.length)
  }
  return ''
}

export function saveSession(session) {
  // hostname = portal.yoco.co.za, and we want .yoco.co.za or .rat.yocobeta.co.za
  let domain = window.location.hostname
  if (domain.includes('yocobeta.co.za')) {
    domain = '.yocobeta.co.za'
  } else if (domain.indexOf('.') > -1) {
    domain = window.location.hostname.substr(domain.indexOf('.'))
  }

  setCookie('yoco-auth-cookie', session.token, domain)
  setCookie('userUUID', session.user.uuid, domain)
  setCookie('businessUUID', session.business.uuid, domain)

  window?.yocoStorage?.setItem('token', session.token)
  window?.yocoStorage?.setItem('userUUID', session.user.uuid)
  window?.yocoStorage?.setItem('businessUUID', session.business.uuid)
  window?.yocoStorage?.setItem('session', JSON.stringify(session))

  setCookie('yat', '', domain, -365)
}
