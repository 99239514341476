import React from 'react'
import PropTypes from 'prop-types'
import { List } from 'immutable'

import { PopupComponent } from 'libs/modals'
import { Tabs, TabPanel } from 'libs/tabs'
import { makeTestID } from 'libs/utils'

import AddProductList from './List'
import AddProductForm from './Form'
import { productToInvoiceEntry } from './utils'
import classes from './Popup.module.scss'

const baseTestID = 'addProductPopup'

class AddProductPopup extends PopupComponent {
  state = {
    tabIndex: 0,
  }

  getContent() {
    const { testID } = this.props
    return (
      <div className={classes.root}>
        <Tabs tabIndex={this.state.tabIndex} tabChanged={(tabIndex) => this.setState({ tabIndex })}>
          <TabPanel
            title='Select a product'
            testID={makeTestID(testID, baseTestID, 'tab', 'selectProduct', 'tabTitle')}
          >
            <AddProductList
              onDone={this.props.onDone}
              onAddProductClick={() => this.setState({ tabIndex: 1 })}
              testID={makeTestID(testID, baseTestID, 'tab', 'selectProduct', 'list')}
            />
          </TabPanel>
          <TabPanel
            title='New product'
            testID={makeTestID(testID, baseTestID, 'tab', 'newProduct', 'tabTitle')}
          >
            {this.state.tabIndex === 1 ? (
              <AddProductForm
                trackingEvents={this.props.trackingEvents}
                onSuccess={({ data: { product } }) => {
                  this.props.onDone?.(List([productToInvoiceEntry(product)]))
                }}
                testID={testID}
              />
            ) : null}
          </TabPanel>
        </Tabs>
      </div>
    )
  }
}

AddProductPopup.propTypes = {
  onDone: PropTypes.func,
  testID: PropTypes.string,
}

export default AddProductPopup
