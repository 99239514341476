import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { SelectField } from 'libs/forms'
import { ListItem } from 'libs/list'
import { formatCurrency, makeTestID } from 'libs/utils'
import CheckboxButton from 'components/buttons/CheckboxButton'

import { productToInvoiceEntry } from './utils'

function AddProductListItem({ product, selection, onSelectionChange, testID }) {
  const handleNewSelection = useCallback(
    (selectedVariant) => {
      onSelectionChange(productToInvoiceEntry(product, selectedVariant))
    },
    [product, onSelectionChange]
  )

  const handleSelectionVariantChange = useCallback(
    (choiceIndex, value) => {
      const selectedVariant = (product.variants || []).find(({ choiceValues }) =>
        choiceValues.every((choiceValue, index) =>
          index === choiceIndex
            ? choiceValue === value
            : choiceValue === selection.getIn(['variantChoiceValues', index])
        )
      )

      handleNewSelection(selectedVariant)
    },
    [product, selection, handleNewSelection]
  )

  return (
    <ListItem
      testID={makeTestID(testID, 'AddProductListItem')}
      accessoryLeft={<CheckboxButton selected={!!selection} />}
      onClick={() => {
        if (selection) {
          onSelectionChange(undefined)
        } else {
          handleNewSelection()
        }
      }}
      title={product.tile?.name}
      subTitle={
        selection ? selection.get('variantChoiceValues', []).toArray().join(', ') : undefined
      }
      description={formatCurrency(selection?.get('price') || product.defaultPrice, 'ZAR')}
    >
      {selection && product.variantGroups?.length ? (
        <div style={{ paddingLeft: 40, marginTop: 12 }}>
          {product.variantGroups.map(({ choiceName, choiceOptions }, choiceIndex) => (
            <SelectField
              key={choiceName}
              label={choiceName}
              value={selection.getIn(['variantChoiceValues', choiceIndex])}
              options={choiceOptions.map((value) => ({ label: value, value }))}
              onChange={(selectedOption) => {
                handleSelectionVariantChange(choiceIndex, selectedOption.value)
              }}
              isFullWidth
            />
          ))}
        </div>
      ) : undefined}
    </ListItem>
  )
}

AddProductListItem.propTypes = {
  product: PropTypes.object.isRequired,
  selection: PropTypes.object,
  onSelectionChange: PropTypes.func,
  testID: PropTypes.string,
}

export default AddProductListItem
